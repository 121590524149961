// app/page.tsx
'use client';

import { Box, Heading, Text, Link, SimpleGrid, Icon } from '@chakra-ui/react';
import { FaList, FaLink, FaRandom } from 'react-icons/fa';
import NextLink from 'next/link';

export default function HomePage() {
  return (
    <Box p={8}>
      <Heading as="h1" mb={2}>
        Welcome to Adaptaworks
      </Heading>
      <Text fontSize="sm" fontWeight="bold" color="gray.600" mb={6}>
        by People Ops
      </Text>
      <Text mb={6}>
        Adaptaworks is a grassroots NextJS platform by The Adaptavist Group, designed for rapid prototype to deployment of lightweight, ultra low cost, serverless, microservices.
      </Text>
      <SimpleGrid columns={[1, null, 3]} spacing={10}>
        <Box textAlign="center" borderWidth="1px" borderRadius="lg" p={4}>
          <Icon as={FaList} w={10} h={10} mb={4} />
          <Heading as="h2" size="md" mb={2}>
            Domain Aggregator
          </Heading>
          <Text mb={4}>
            List all of our domains in one place for easy management.
          </Text>
          <Link as={NextLink} href="/domains" color="teal.500" fontWeight="bold">
            Go to Domain Aggregator
          </Link>
        </Box>
        <Box textAlign="center" borderWidth="1px" borderRadius="lg" p={4}>
          <Icon as={FaLink} w={10} h={10} mb={4} />
          <Heading as="h2" size="md" mb={2}>
            URL Shortener
          </Heading>
          <Text mb={4}>
            Shorten URLs for easy sharing and tracking.
          </Text>
          <Link as={NextLink} href="/shortener" color="teal.500" fontWeight="bold">
            Go to URL Shortener
          </Link>
        </Box>
        <Box textAlign="center" borderWidth="1px" borderRadius="lg" p={4}>
          <Icon as={FaRandom} w={10} h={10} mb={4} />
          <Heading as="h2" size="md" mb={2}>
            Domain Redirector
          </Heading>
          <Text mb={4}>
            Control our parked and legacy domains and paths.
          </Text>
          <Link as={NextLink} href="/page-redirects" color="teal.500" fontWeight="bold">
            Go to Domain Redirector
          </Link>
        </Box>
      </SimpleGrid>
    </Box>
  );
}