// RootLayout.tsx
'use client';

import {
  ChakraProvider,
  Box,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Heading,
  VStack,
  Divider,
  Link as ChakraLink,
  useColorModeValue,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { SessionProvider, useSession, signOut } from 'next-auth/react';
import { ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import NextLink from 'next/link';
import theme from '../theme';
import AuthWrapper from '../components/AuthWrapper';

export default function RootLayout({ children }: { children: ReactNode }) {
  return (
    <html lang="en">
      <body>
        <SessionProvider>
          <ChakraProvider theme={theme}>
            <AuthWrapper>
              <Flex direction="column" minH="100vh">
                <Header />
                <Flex flex="1" mt="60px">
                  <Sidebar />
                  <ContentWrapper>{children}</ContentWrapper>
                </Flex>
              </Flex>
            </AuthWrapper>
          </ChakraProvider>
        </SessionProvider>
      </body>
    </html>
  );
}

const menuItems: { href: string; label: string }[] = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/shortener', label: 'URL Shortener' },
  { href: '/domains', label: 'Domain Aggregator' },
  { href: '/page-redirects', label: 'Domain Redirects' },
  { href: '/gmail/delegations', label: 'Gmail Delegations' },
  { href: '/settings', label: 'Settings' },
];

const Header = () => {
  const { data: session } = useSession();
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setIsClient(true);
  }, []);

  const userName = session?.user?.name ?? '';
  const userImage = session?.user?.image ?? '';
  const bgColor = useColorModeValue('brand.500', 'brand.900');
  const textColor = useColorModeValue('black', 'white');

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Flex
        as="header"
        width="100%"
        height="60px"
        alignItems="center"
        justifyContent="space-between"
        px={4}
        bg={bgColor}
        color={textColor}
        boxShadow="md"
        position="fixed"
        top="0"
        zIndex="1000"
      >
        <Flex alignItems="center">
          <Box as="span" display="inline-block" height="40px" color="white">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3732 3732" height="40px" width="40px" fill="currentColor">
              <path d="M3732 2634c4 134-67 261-180 333 150 250 272 701-174 666v-3c-248-27-474-133-698-234a1767 1767 0 0 1-765 166c131-131 264-261 403-383 33 20 67 39 99 62l2 4c85 51 172 99 261 144 60-25 114-62 169-94-125-86-284-155-407-243l-4-3c62-78 172-92 234-175 49-72 125-133 156-213-89-134-232-239-339-361-88-57-36 148-269 294-27 20-77-3-58-38 50-48 104-89 138-150 49-83 62-194 3-274-28-64-679-377-459-386 153 52 401 319 547 180 56-52 77-153 17-210l-517-390c-169-118-325 342-429 448-71 184 59 406 73 600-391-338-766-703-1070-1123-48-87-79 118-100 159 322 430 709 817 1118 1169a8557 8557 0 0 1-630 577 1679 1679 0 0 1-499-1743C-234 629-103 121 912 603c7-69 82-121 149-99 125-78 258-138 400-182-64-55-109-149-38-219 189-99 444 16 590 151 1018 18 1807 1076 1536 2058 90 88 178 188 183 322ZM1528 144a585 585 0 0 1 597 685c-41 116-8 173 79 253-69 77-149 146-211 229-80-79-224-117-303-18-403 94-798-283-702-689 11-24 29-33 55-29 141 88 211 322 385 344l199-42c102-36 94-172 135-257 69-133-475-438-234-476ZM366 584c153-9 298 46 435 109a2336 2336 0 0 0-364 509c-97-141-383-576-71-618Zm2128 344c80-6 153 53 176 129-12 171-274 317-374 462l-235-179c147-129 257-330 433-412Zm257 288c121-5 246 154 149 256-121 99-223 246-356 320-22-113-81-154-174-219 129-110 224-293 381-357Zm258 310c129-17 249 154 148 255-182 125-357 457-577 460-107-22-190-156-102-244 181-148 327-361 531-472Zm-500 340 1-8 5 2-6 6Zm726-7c117-2 216 147 136 245-158 118-286 397-484 418-107-16-189-151-118-243 158-133 287-322 466-420Zm122 365c188 166 460 455 171 666-258 181-448-118-593-294 182-52 281-254 422-372Zm-151 762c77 32 165 46 246 21 343 606-217 483-578 290 111-101 236-192 332-311Z" fill="currentColor" fill-rule="evenodd"/>
              <path d="m2396 1921-5 5 5-5Z" fill="#444" fill-rule="evenodd" opacity=".8"/>
              <path d="M3351 2509c222-20 197 336-20 285-154-35-137-272 20-285Z" fill="currentColor" fill-rule="evenodd"/>
            </svg>
          </Box>
          <Heading size="md" ml={2} color="white">
            ADAPTA.WORKS
          </Heading>
        </Flex>
        <Flex alignItems="center">
          
          <Menu>
            <MenuButton as={Button} rounded="full" variant="link" cursor="pointer" minW={0}>
              <Avatar size="sm" src={userImage} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => signOut()}>Sign out</MenuItem>
            </MenuList>
          </Menu>
          <IconButton
            ml={3}
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
          />
        </Flex>
      </Flex>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <VStack align="start">
              {menuItems.map((item) => (
                <NextLink key={item.href} href={item.href} passHref>
                  <ChakraLink
                    fontSize="lg"
                    lineHeight={10}
                    p={0}
                    w="100%"
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    _hover={{ textDecoration: 'none', bg: useColorModeValue('gray.200', 'gray.700') }}
                    onClick={onClose}
                  >
                    {item.label}
                  </ChakraLink>
                </NextLink>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const Sidebar = () => (
  <Box
    as="aside"
    width={{ base: 'full', md: '250px' }}
    bg={useColorModeValue('gray.100', 'gray.900')}
    p={4}
    display={{ base: 'none', md: 'block' }}
  >
    <VStack align="start" spacing={3}>
      {menuItems.map((item) => (
        <NextLink key={item.href} href={item.href} passHref>
          <ChakraLink
            p={2}
            fontSize="md"
            w="100%"
            mb={200} 
            // eslint-disable-next-line react-hooks/rules-of-hooks
            _hover={{ textDecoration: 'none', bg: useColorModeValue('gray.200', 'gray.700') }}
          >
            {item.label}
          </ChakraLink>
        </NextLink>
      ))}
    </VStack>
  </Box>
);

const ContentWrapper = ({ children }: { children: ReactNode }) => (
  <Box as="main" flex="1" p={4}>
    {children}
  </Box>
);